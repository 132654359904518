import React from 'react';
import './Wizard.css';

const WizardForm = ({ currentSection }) => {
    const sections = [
      { title: "Personal Information" },
      { title: "Birth Details" },
      { title: "Family and Other Details" },  
    ];
  
    return (
      <div className="wizard-container">
        <div className="wizard-steps">
          {sections.map((section, index) => {
            const isActive = currentSection === index;
            const isCompleted = currentSection > index;
  
            return (
              <React.Fragment key={index}>
                <div
                  className={`wizard-step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}
                >
                  <div className="wizard-step-circle">{index + 1}</div>
                  <div className="wizard-step-title">{section.title}</div>
                </div>
  
                {index < sections.length - 1 && (
                  <div className={`wizard-connector ${isCompleted ? 'completed' : ''}`} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };  

export default WizardForm;
