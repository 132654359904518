import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './Login.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 

  const navigate = useNavigate();

  // Login handler
  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage('Please fill out all fields');
      return;
    }
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post('https://backend.mangalya.sdvtemple.in/api/auth/login', /* https://backend.mangalya.sdvtemple.in */
        {
        email,
        password,
      });
      const { token } = response.data;

      const decodedToken = jwtDecode(token);

      localStorage.setItem('token', token);

      navigate('/');
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      setErrorMessage('Login failed. Please check your email and password.');
    } finally {
      setIsLoading(false);
    }
  };

  // Registration handler
  const handleRegister = async () => {
    if (!username || !email || !password) {
      setErrorMessage('Please fill out all fields');
      return;
    }
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post('http://localhost:5000/api/auth/register', {
        username,
        email,
        password,
      });
      console.log('Registration successful:', response.data);
      alert('Registration successful!');
      setIsRegistering(false);
    } catch (error) {
      console.error('Error registering user:', error.response?.data || error.message);
      setErrorMessage('Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-register-container">
      <h2 className="login-h2">{isRegistering ? 'Register' : 'Login'}</h2>

      {isRegistering ? (
        <div className="login-form-container">
          <input
            className="login-input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            className="login-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='login-auth-button' onClick={handleRegister} disabled={isLoading}>
            {isLoading ? 'Registering...' : 'Register'}
          </button>
          <p onClick={() => setIsRegistering(false)} className="login-toggle-link">Already have an account? Login</p>
        </div>
      ) : (
        <div className="login-form-container">
          <input
            className="login-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='login-auth-button' onClick={handleLogin} disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          <p onClick={() => setIsRegistering(true)} className="login-toggle-link">Don't have an account? Register</p>
        </div>
      )}

      {errorMessage && <p className="login-error-message">{errorMessage}</p>} {/* Display error message */}
    </div>
  );
}

export default Login;
