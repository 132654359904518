// ProfileDetails.js (Frontend)

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import './ProfileDetails.css'; // Import the CSS file

const ProfileDetails = () => {
  const { EMAIL_ID } = useParams(); // Extract the EMAIL_ID from the URL
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(0); // Track current photo for arrows
  const [isModalOpen, setModalOpen] = useState(false); // Toggle photo modal

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/matrimonial/profiles/${EMAIL_ID}`); 
        if (!response.ok) {
          throw new Error('Profile not found');
        }
        const data = await response.json();
        setProfile(data);
      } catch (error) {
        setError('Error fetching profile');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [EMAIL_ID]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!profile) return <p>No profile found</p>;

  const handlePrevPhoto = () => {
    setCurrentPhoto((prev) => (prev === 0 ? profile.photos.length - 1 : prev - 1));
  };

  const handleNextPhoto = () => {
    setCurrentPhoto((prev) => (prev === profile.photos.length - 1 ? 0 : prev + 1));
  };

  const handlePhotoClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="profile-details-container">
      <div className="photo-section">
        <div className="photo-wrapper">
        <img
            src={`https://backend.mangalya.sdvtemple.in/uploads/${profile.photos[currentPhoto]}`}
            alt={`Profile Photo ${currentPhoto + 1}`}
            onClick={handlePhotoClick}
          />

          <div className="nav-arrows">
            <span className="prev-arrow" onClick={handlePrevPhoto}>◀</span>
            <span className="next-arrow" onClick={handleNextPhoto}>▶</span>
          </div>
        </div>
      </div>

      <div className="details-section">
        <table className="details-table">
          <tbody>
            <tr>
              <td><strong>Name:</strong></td>
              <td>{profile.FIRST_NAME} {profile.LAST_NAME}</td>
            </tr>
            <tr>
              <td><strong>Gender:</strong></td>
              <td>{profile.GENDER}</td>
            </tr>
            <tr>
              <td><strong>Date of Birth:</strong></td>
              <td>{new Date(profile.DATE_OF_BIRTH).toLocaleDateString()}</td>
            </tr>
            <tr>
              <td><strong>Place of Birth:</strong></td>
              <td>{profile.PLACE_OF_BIRTH}, {profile.STATE_OF_BIRTH}, {profile.COUNTRY_OF_BIRTH}</td>
            </tr>
            <tr>
              <td><strong>Occupation:</strong></td>
              <td>{profile.OCCUPATION}</td>
            </tr>
            <tr>
              <td><strong>City:</strong></td>
              <td>{profile.CITY}, {profile.STATE}</td>
            </tr>
            <tr>
              <td><strong>Mother Tongue:</strong></td>
              <td>{profile.MOTHER_TONGUE}</td>
            </tr>
            <tr>
              <td><strong>About Me:</strong></td>
              <td>{profile.ABOUT_ME}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={handleCloseModal}>
          <span className="close">&times;</span>
          <img className="modal-content" src={`https://backend.mangalya.sdvtemple.in/uploads/${profile.photos[currentPhoto]}`} alt={`Profile Photo ${currentPhoto + 1}`} />
        </div>
      )}
    </div>
  );
};

export default ProfileDetails;
