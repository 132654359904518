import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Header';
import HomePage from './HomePage';
import MatrimonialForm from './MatrimonialForm';  
import Login from './Login';
import ProfileDetails from './ProfileDetails';

function App() {
  return (
    <Router> 
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />  
          <Route path="/MatrimonialForm" element={<MatrimonialForm />} />  
          <Route path="/Login" element={<Login />} />
          <Route path="/profile/:EMAIL_ID" element={<ProfileDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
