import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode'; // Make sure jwtDecode is imported correctly
import { useNavigate } from 'react-router-dom';
import './App.css'; // Merged CSS file
import MainImage from './images/mainphoto.jpg'; 

const HomePage = () => {
  const [profiles, setProfiles] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state to check if the user is logged in
  const navigate = useNavigate();

  // Check if the user is logged in based on the presence and validity of a token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          setIsLoggedIn(true); // Token is valid, user is logged in
        }
      } catch (error) {
        console.error('Invalid token:', error);
        setIsLoggedIn(false); // Invalid token, consider the user logged out
      }
    } else {
      setIsLoggedIn(false); // No token found, user is not logged in
    }
  }, []);

  const handleLoginClick = () => {
    navigate('/Login');
  };

  const handleCreateProfileClick = () => {
    navigate('/MatrimonialForm');
  };

  const handleViewProfileClick = () => {
    navigate('/Profile'); // Navigate to a profile viewing page
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await fetch('https://backend.mangalya.sdvtemple.in/api/matrimonial/profiles'); /* https://backend.mangalya.sdvtemple.in */
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log("Fetched profiles data:", data);
        setProfiles(data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
    fetchProfiles();
  }, []);

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    return Math.abs(ageDate.getUTCFullYear() - 1970); // Calculate age
  };

  return (
    <div className="container-fluid h-100">
      {/* Background Image Section */}
      <div
        className="overlay"
        style={{
          backgroundImage: `url(${MainImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="welcome-text text-center">
          <h1 className="display-4">Welcome to Mangalya App</h1>
        </div>

        {/* Conditional buttons based on login status */}
        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-white">
          {!isLoggedIn ? (
            <button className="btn btn-primary create-profile-btn mt-4" onClick={handleLoginClick}>
              Login
            </button>
          ) : (
            <>
              <button className="btn btn-primary create-profile-btn mt-4" onClick={handleCreateProfileClick}>
                Create Profile
              </button>
              <button className="btn btn-secondary view-profile-btn mt-3" onClick={handleViewProfileClick}>
                View Profile
              </button>
            </>
          )}
        </div>
      </div>

      {/* Profile List Section */}
      <div className="profile-list-container mt-5">
        <h2 className="text-center mb-4">Available Profiles</h2>
        <div className="profile-list d-flex flex-wrap justify-content-center">
          {profiles.length > 0 ? (
             profiles.map((profile) => {
              console.log('Profile Photo URL:', profile.photos[0]); // Log the photo URL for each profile

              return (
                <div
                  key={profile.EMAIL_ID}
                  className="profile-item card m-2"
                  style={{ width: '18rem', cursor: 'pointer' }}
                  onClick={() => navigate(`/profile/${profile.EMAIL_ID}`)}
                >
                  {profile.photos && profile.photos.length > 0 ? (
  <img
    src={profile.photos[0]}  // Ensure this URL is valid
    alt={`${profile.FIRST_NAME} ${profile.LAST_NAME}`}
    className="card-img-top"
    style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}  
  />
) : (
  <img
    src="https://backend.mangalya.sdvtemple.in/uploads/default-profile-image.jpg"
    alt="Default"
    className="card-img-top"
    style={{ maxHeight: '200px', width: '100%', objectFit: 'cover' }}  
  />
)}

                  <div className="card-body">
                    <h5 className="card-title">{profile.FIRST_NAME} {profile.LAST_NAME}</h5>
                    <p className="card-text">Age: {calculateAge(profile.DATE_OF_BIRTH)}</p>
                    <p className="card-text">Place: {profile.CITY}, {profile.STATE || 'Not specified'}</p>
                    <p className="card-text">Language: {profile.MOTHER_TONGUE || 'Not specified'}</p>
                    <p className="card-text">Profession: {profile.OCCUPATION || 'Not specified'}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;