import React, { useState } from "react";
import './App.css';  
import MainImage from './images/FormPhotoCopy.png';
import WizardForm from "./Wizard";

const MatrimonialForm = () => {
  const [formData, setFormData] = useState({
    ID: "",
    EMAIL_ID: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    GENDER: "M",
    DATE_OF_BIRTH: "",
    TIME_OF_BIRTH: "",
    PLACE_OF_BIRTH: "",
    COUNTRY_OF_BIRTH: "",
    GOTRAM: "",
    NAKSHATRAM: "",
    RAASI: "",
    MOTHER_TONGUE: "",
    CITIZENSHIP: "",
    FATHER_NAME: "",
    MOTHER_NAME: "",
    BROTHERS: "",
    SISTERS: "",
    RELIGION: "",
    COMMUNITY: "",
    SUBSECT: "",
    ACADEMIC_QUALIFICATION: "",
    OCCUPATION: "",
    FOLLOWING_SWAMIJI_SINCE: "",
    CITY: "",
    STATE: "",
    COUNTRY: "",
    WHATSAPP_NUMBER: "",
    FACEBOOK_ID:"",
    HOBBIES: "",
    HEIGHT: "",
    COMPLEXION: "",
    LANGUAGES_SPOKEN: "",
    ABOUT_ME: "",
    EXPECTATIONS: "",
    SUBMITTED_BY: "",
    photos: [null, null, null],  // Initially, 3 slots for photos set to null
  });

  const [currentSection, setCurrentSection] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhotoChange = (e, index) => {
    const file = e.target.files[0];
    const updatedPhotos = [...formData.photos];
    updatedPhotos[index] = file;
    setFormData((prevData) => ({
      ...prevData,
      photos: updatedPhotos
    }));
    console.log(`Selected Photo ${index + 1}:`, file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  // Prevent default form submission behavior
  
    // Debugging: Check if handleSubmit is being called
    console.log("Form is being submitted");
  
    if (currentSection === sections.length - 1) {
      const formDataToSend = new FormData();
      
      // Append all form data except photos
      for (const key in formData) {
        if (key !== 'photos') {
          formDataToSend.append(key, formData[key]);
        }
      }
      
      // Append photos separately
      formData.photos.forEach((photo) => {
        if (photo) {
          formDataToSend.append('photos', photo); // Attach photo1, photo2, etc.
        }
      });
  
      // Debugging: Check formData before sending
      console.log("FormData to send: ", formDataToSend);
  
      try {
        const response = await fetch('https://backend.mangalya.sdvtemple.in/api/matrimonial/submit', /* https://backend.mangalya.sdvtemple.in */
          {
          method: 'POST',
          body: formDataToSend,  // Send FormData directly
        });
  
        // Debugging: Check if response is received
        console.log("Response received");
  
        if (!response.ok) {
          throw new Error('Failed to submit data');
        }
  
        const result = await response.json();
        console.log(result.message); // Success message
        setIsSubmitted(true);
  
        setTimeout(() => {
          window.location.href = '/';  // Redirect to homepage after 5 seconds
        }, 5000);
  
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      // Debugging: Log to ensure it's moving to the next section
      console.log("Moving to the next section");
      // If not the last section, move to the next one
      nextSection(e);
    }
  };

  const sections = [
    {
      title: "Personal Information",
      fields: [
        { label: "ID (Optional):", name: "ID", type: "text", required: true },
        { label: "Email:", name: "EMAIL_ID", type: "email", required: true },
        { label: "First Name:", name: "FIRST_NAME", type: "text", required: true },
        { label: "Last Name:", name: "LAST_NAME", type: "text", required: true },
        { label: "Gender:", name: "GENDER", type: "select", options: ["Male", "Female"], required: true },
        { label: 'Whatsapp Number:', name: 'WHATSAPP_NUMBER', type: 'tel', required: true },
        { label: 'Occupation:', name: 'OCCUPATION', type: 'text' },
        { label: "Date of Birth:", name: "DATE_OF_BIRTH", type: "date", required: true },
        { label: "Time of Birth:", name: "TIME_OF_BIRTH", type: "time", required: true },
      ],
    },
    {
      title: "Birth Details",
      fields: [
        { label: "Place of Birth:", name: "PLACE_OF_BIRTH", type: "text", required: true },
        { label: "Country of Birth:", name: "COUNTRY_OF_BIRTH", type: "text" },
        { label: "Gotram:", name: "GOTRAM", type: "text" },
        { label: "Nakshatram:", name: "NAKSHATRAM", type: "text" },
        { label: "Raasi:", name: "RAASI", type: "text" },
        { label: "Mother Tongue:", name: "MOTHER_TONGUE", type: "text", required: true },
        { label: 'Other Languages Known:', name: 'OTHER_LANGUAGES_KNOWN', type: 'text' },
      ],
    },
    {
      title: 'Family and Other Details',
      fields: [
        { label: "Father's Name:", name: 'FATHER_NAME', type: 'text' },
        { label: "Mother's Name:", name: 'MOTHER_NAME', type: 'text' },
        { label: 'Num of Brothers:', name: 'BROTHERS', type: 'text' },
        { label: 'Num of Sisters:', name: 'SISTERS', type: 'text' },
        { label: 'Religion:', name: 'RELIGION', type: 'text' },
        { label: 'Community:', name: 'COMMUNITY', type: 'text' },
        { label: 'Subsect:', name: 'SUBSECT', type: 'text' },
        { label: 'Academic Qualification:', name: 'ACADEMIC_QUALIFICATION', type: 'text' },
        { label: 'Citizenship:', name: 'CITIZENSHIP', type: 'text' },
        { label: 'Following Swamiji Since:', name: 'FOLLOWING_SWAMIJI_SINCE', type: 'text' },
        { label: 'City:', name: 'CITY', type: 'text', required: true },
        {
          label: 'State:',
          name: 'STATE',
          type: 'select',
          options: [
            'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
            'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
            'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya',
            'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim',
            'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
            'West Bengal'
          ],
          required: true
        },
        { label: 'Country:', name: 'COUNTRY', type: 'text', required: true },
        { label: 'Hobbies:', name: 'HOBBIES', type: 'text' },
        { label: 'Facebook ID:', name: 'FACEBOOK_ID', type: 'text' },
        { label: 'Height:', name: 'HEIGHT', type: 'text' },
        { label: 'Complexion:', name: 'COMPLEXION', type: 'text' },
        { label: 'About Me:', name: 'ABOUT_ME', type: 'text' },
        { label: 'Expectations:', name: 'EXPECTATIONS', type: 'text' },
        { label: 'Submitted By:', name: 'SUBMITTED_BY', type: 'text' },
        { 
          label: 'Upload Photo 1:', 
          name: 'photo1', 
          type: 'file', 
          onChange: (e) => handlePhotoChange(e, 0), 
          accept: "image/*"  
        },        
        { 
          label: 'Upload Photo 2:', 
          name: 'photo2', 
          type: 'file', 
          onChange: (e) => handlePhotoChange(e, 1), 
          accept: "image/*"  
        },        
        { 
          label: 'Upload Photo 3:', 
          name: 'photo3', 
          type: 'file', 
          onChange: (e) => handlePhotoChange(e, 2), 
          accept: "image/*"  
        },        
      ],
    },
  ];

  const renderSection = () => {
    const { title, fields } = sections[currentSection];
    return (
      <div>
        <h2>{title}</h2>
        {fields.map((field, index) => (
          <div className="form-group" key={index}>
            <label>{field.label}</label>
            {field.type === "select" ? (
              <select
                name={field.name}
                value={formData[field.name]}
                onChange={handleChange}
                required={field.required}
              >
                {field.options.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                name={field.name}
                onChange={field.onChange ? field.onChange : handleChange}
                accept={field.type === "file" ? "image/*" : ""}
                multiple={field.type === "file"}
              />
            )}
          </div>
        ))}
      </div>
    );
  };  

  const nextSection = (e) => {
    e.preventDefault();
    if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
    }
  };

  const prevSection = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };

  return (
    <div className="container-fluid">
      {isSubmitted ? (
        <div className="success-message">
          <div className="tick-animation">
            ✅
          </div>
          <p>Successfully submitted the form!</p>
        </div>
      ) : (
        <>
          <div className="overlay" 
            style={{ 
              backgroundImage: `url(${MainImage})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              height: '60vh', 
              backgroundColor: 'rgba(0, 0, 0, 0.5)' 
            }}
          ></div> 
          <WizardForm currentSection={currentSection} sections={sections} />
          <div className="form-container">
            <form className="matrimonial-form" onSubmit={handleSubmit}>
              {renderSection()}
              <div className="button-group">
                {currentSection > 0 && (
                  <button type="button" onClick={prevSection}>Previous</button>
                )}
                <button
                  type={currentSection === sections.length - 1 ? "submit" : "button"}
                  onClick={currentSection === sections.length - 1 ? undefined : nextSection}
                >
                  {currentSection < sections.length - 1 ? "Next" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default MatrimonialForm;
